import axios from 'axios';
import { AuthHelper } from 'utils/authHelper.util';
import { ICompanyInfo } from 'models/companyInfo.model';

export default class DadataApiService {
  public static async getInfoByBic(bic: string) {
    return (await axios.get('/api/v1/da_data/banks/by_bic', {
      method: 'GET',
      params: { bic },
    })).data.bank?.[0];
  }

  public static async getCompaniesByInn(inn: string): Promise<Partial<Array<ICompanyInfo>>> {
    return await AuthHelper.fetch('/api/v2/companies', {
      method: 'GET',
      params: { inn },
    });
  }
}
